export default {
    path: 'challenge',
    component: { render(c) { return c('router-view') } },
    name: 'Retos',
    redirect: 'challenge',
    children: [
        {
            path: '',
            component: () => import('@/views/challenge/Section'),
            meta: { title: 'Retos', container: true, access: ['admin', 'doctor']}
        },
        {
            path: 'create',
            name: 'Añadir reto',
            component: () => import('@/views/challenge/Create'),
            meta: { title: 'Añadir reto', container: true, access: ['admin']}
        },
    ]
}