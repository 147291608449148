<template>
    <div class="alert alert-danger" role="alert">
      {{ msg }}
      <div class="progress mt-2">
        <div
          class="progress-bar bg-danger"
          role="progressbar"
          :style="{ width: progressBarWidth }"
          :aria-valuenow="progressBarWidth"
          aria-valuemin="0"
          aria-valuemax="100"
        >
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        timeLeft: 5, 
      };
    },
    props:{
        msg: String,
    },
    computed: {
      progressBarWidth() {
        return `${(this.timeLeft / 5) * 100}%`; 
      },
    },
    mounted() {
      const interval = setInterval(() => {
        if (this.timeLeft > 0) { 
          this.timeLeft--;
        } else {
          clearInterval(interval);
          this.isVisible = false;
          this.$store.state.errorAlert = { status: false, msg: ''}
        }
      }, 500); 
    },
  };
  </script>
  
  <style scoped>
  .alert {
    margin: 20px;
  }
  
  .progress {
    height: 6px;
  }
  </style>
  