<template>
	<TheContainer />
</template>

<script>
import TheContainer from "@/containers/TheContainer"
import { checkUser } from "@/utils/token";

export default {
	name: "App",
	components: {
		TheContainer
	},
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				this.checkAccess();
				document.title = to.meta.title ? `${to.meta.title} - INTRACK` : "INTRACK";
			}
		}
	},
	methods: {
		checkAccess() {
			this.$store.state.errorAlert = { status: false, msg: ''};
            if (this.$route.path == '/') return;

            this.$store.state.isAdmin = checkUser()?.user?.type == 'admin'
		}
	}
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
