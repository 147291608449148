const generateSidebarNavItem = (name, to, icon) => ({ _name: 'CSidebarNavItem', name, to, icon });

const sideBarToRender = (typeOfUser) => {
    const commonItems = [
        generateSidebarNavItem('Alertas', '/alert', 'cilBell'),
        generateSidebarNavItem('Consejos', '/advice', 'cilLightbulb'),
        generateSidebarNavItem('Cuestionarios', '/quest', 'cilCheckCircle'),
        generateSidebarNavItem('Retos', '/challenge', 'cilBadge'),
        generateSidebarNavItem('Patologías', '/pathology', 'cilPeople'),
    ];

    const userTypeSpecificItems = typeOfUser === 'admin'? 
    [
        generateSidebarNavItem('Doctores', '/doctor', 'cilHospital'),
    ]
    : 
    [
        generateSidebarNavItem('Pacientes', '/patient', 'cilPeople'),
    ];

    return [
        {
            _name: 'CSidebarNav',
            _children: [
                generateSidebarNavItem('Inicio', '/', 'cil-home'),
                {
                    _name: 'CSidebarNavTitle',
                    _children: [`secciones de ${typeOfUser}`],
                },
                ...userTypeSpecificItems,
                {
                    _name: 'CSidebarNavTitle',
                    _children: ['secciones'],
                },
                ...commonItems,
                {
                    _name: 'CSidebarNavTitle',
                    _children: ['sesión'],
                },
                generateSidebarNavItem('Cerrar sesión', '/login', 'cilXCircle'),
            ],
        },
    ];
};

export default sideBarToRender;
