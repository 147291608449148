export default {
    path: 'alert',
    component: { render(c) { return c('router-view') } },
    name: 'Alertas',
    redirect: 'alert',
    children: [
        {
            path: '',
            component: () => import('@/views/alert/Section'),
            meta: { title: 'Alertas', container: true, access: ['admin', 'doctor'] }
        },
        {
            path: 'create',
            name: 'Crear alerta',
            component: () => import('@/views/alert/Create'),
            meta: { title: 'Crear alerta', container: true, access: ['admin']}
        },
    ]
}