import Vue from 'vue'
import Router from 'vue-router'

import homeRouterPaths from '@/router/home.js'
import doctorRouterPaths from '@/router/doctor.js'
import patientRouterPaths from '@/router/patient.js'
import pathologyRouterPaths from '@/router/pathology.js'
import challengeRouterPaths from '@/router/challenge.js'
import alertsRouterPaths from '@/router/alert.js'
import questRouterPaths from '@/router/quest.js'
import adviceRouterPaths from '@/router/advice.js'

Vue.use(Router)

function configRoutes() {
  return [
    {
      path: '/login',
      name: 'Identificación',
      component: () => import('@/views/Login'),
      meta: {container: false, access: ['admin', 'doctor', 'not-logged'] }
    },
    {
      path: '',
      redirect: '',
      component: { render(c) { return c('router-view') } },
      children: [
        { path: '404', name: 'Not found', component: () => import('@/views/Page404'), container: true, access: ['admin', 'doctor']},
        { path: '500', name: 'Fallo del servidor', component: () => import('@/views/Page500'), container: true, access: ['admin', 'doctor']},
        homeRouterPaths,
        doctorRouterPaths,
        patientRouterPaths,
        pathologyRouterPaths,
        challengeRouterPaths,
        alertsRouterPaths,
        questRouterPaths,
        adviceRouterPaths,
        { path: '*', name: 'Página no encontrada', component: () => import('@/views/Page404'), container: true, access: ['admin', 'doctor'] },
      ]
    },
  ]
}

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})