export default {
    path: 'advice',
    component: { render(c) { return c('router-view') } },
    name: 'Consejos',
    redirect: 'advice',
    children: [
        {
            path: '',
            component: () => import('@/views/advice/Section'),
            meta: { title: 'Consejos', container: true, access: ['admin', 'doctor' ] }
        },
        {
            path: 'create',
            name: 'Crear consejo',
            component: () => import('@/views/advice/Create'),
            meta: { title: 'Crear consejo', container: true, access: ['admin' ] }
        },
    ]
}