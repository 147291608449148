export default {
    path: 'patient',
    component: { render(c) { return c('router-view') } },
    redirect: 'patient',
    children: [
        {
            path: '',
            name: 'Pacientes',
            component: () => import('@/views/patient/Section'),
            meta: { title: 'Pacientes', container: true, access: ['admin', 'doctor'] }
        },
        {
            path: 'create',
            name: 'Añadir paciente',
            component: () => import('@/views/patient/Create'),
            meta: { title: 'Añadir paciente', container: true, access: ['doctor']}
        },
        {
            path: 'info/:id',
            name: 'Información del paciente',
            component: () => import('@/views/patient/Edit'),
            meta: { title: 'Información del paciente', container: true, access: ['admin', 'doctor']}
        },
        {
            path: 'sessions/:id',
            component: { render(c) { return c('router-view') } },
            children: [
                {
                    path: '',
                    name: 'Sesiones del paciente',
                    component: () => import('@/views/session/Section'),
                    meta: { title: 'Ver sesiones', container: true, access: ['admin', 'doctor'] },
                },
                {
                    path: 'quest/:id_session',
                    name: 'Información cuestionario',
                    component: () => import('@/views/quest/questView'),
                    meta: { title: 'Cuestionario', container: true, access: ['admin', 'doctor'] }
                },
            ]
        },
        {
            path: 'quests/:id',
            name: 'Información de los cuestionarios del paciente',
            component: () => import('@/views/patient/Quests'),
            meta: { title: 'Información de los cuestionaros del paciente', container: true, access: ['admin', 'doctor']}
        },
    ]
}