import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'

import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'

import CoreuiVueCharts from '@coreui/vue-chartjs'
import { CChartBar } from '@coreui/vue-chartjs'

import { decodePayload } from "@/utils/token"

Vue.use(CoreuiVueCharts)
Vue.component('CChartBar', CChartBar)

Vue.config.performance = true
Vue.use(CoreuiVue)

Vue.prototype.$log = console.log.bind(console)

router.beforeEach((to, from, next) => {
    const user = decodePayload(localStorage.token)
    const isAdmin = user?.type == 'admin'

    if ( !user && to.meta.access?.includes("not-logged") ){ next(); return; }
    if ( user &&  !isAdmin && to.meta.access?.includes("doctor") ){ next(); return; }
    if ( user &&  isAdmin && to.meta.access?.includes("admin") ){ next(); return; }

    next('/login')
})

new Vue({
    el: '#app',
    router,
    store,
    icons,
    components: { App },
    template: '<App/>'
})