export default {
    path: 'doctor',
    component: { render(c) { return c('router-view') } },
    name: 'Doctores',
    redirect: 'doctor',
    children: [
        {
            path: '',
            component: () => import('@/views/doctor/Section'),
            meta: { title: 'Doctores', container: true, access: ['admin'] }
        },
        {
            path: 'create',
            name: 'Crear doctor',
            component: () => import('@/views/doctor/Create'),
            meta: { title: 'Crear doctor', container: true, access: ['admin']}
        },
        {
            path: 'info/:id',
            name: 'Información del doctor',
            component: () => import('@/views/doctor/Edit'),
            meta: { title: 'Información del doctor', container: true, access: ['admin']}
        }
    ]
}