<template>
	<CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
		<CSidebarBrand class="d-md-down-none" to="/">
			<div class="logo-siderbar">
				<img src="@/../public/intrack_white.png" class="desktop-logo" alt="Intrack">
				<img src="@/../public/intrack_logo.png" class="mobile-logo" alt="Intrack">
			</div>
		</CSidebarBrand>

		<CRenderFunction flat :content-to-render="navbar" />
		<CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
	</CSidebar>
</template>

<script>
import sideBarToRender from "./_nav";
import { decodePayload } from "@/utils/token";

export default {
	name: "TheSidebar",
	data() {
		return {
			typeOfUser: null
		}
	},
	created() {
		this.checkingTypeOfUser();
	},
	computed: {
		navbar() {
			return sideBarToRender(this.typeOfUser);
		},
		show() {
			return this.$store.state.sidebarShow;
		},
		minimize() {
			return this.$store.state.sidebarMinimize;
		},
	},
	methods: {
		checkingTypeOfUser() {
			const { type } = decodePayload(localStorage.token);
			this.typeOfUser = type;
		}
	}
};
</script>

<style>
.logo-siderbar img{
    object-fit: contain;
}
.logo-siderbar .desktop-logo{
	width: 155px;
    padding-bottom: 4px;
}
.logo-siderbar .mobile-logo{
	display: none;
	width: 30px;
}
.c-sidebar-minimized .logo-siderbar .desktop-logo {
	display: none;
}
.c-sidebar-minimized .logo-siderbar .mobile-logo {
    display: block;
}
</style>
