export default {
    path: 'pathology',
    component: { render(c) { return c('router-view') } },
    name: 'Patologías',
    redirect: 'pathology',
    children: [
        {
            path: '',
            component: () => import('@/views/pathology/Section'),
            meta: { title: 'Patologías', container: true, access: ['admin', 'doctor']}
        },
        {
            path: 'create',
            name: 'Añadir patologías',
            component: () => import('@/views/pathology/Create'),
            meta: { title: 'Añadir patologías', container: true, access: ['admin']}
        },
    ]
}